import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Bench Press 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Barbell Rows 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`1 Set Max Strict HSPU’s`}</p>
    <p>{`rest 1:00 then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20-KBS’s (53/35)`}</p>
    <p>{`20-Pushups`}</p>
    <p>{`20-Box Jumps (24/20)`}</p>
    <p>{`rest 1:00 then,`}</p>
    <p>{`1 Set, Max Kipping HSPU’s`}</p>
    <p>{`Score=Max Strict/WOD Time/Max Kipping`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free workout so bring a friend! 8:00 & 9:00am
at The Ville, 10:00 & 11:00am at East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Judging for 17.3 will take place on Saturday at THE VILLE ONLY from
10am-2pm.  Sunday hours will be posted tomorrow.  17.3 will be Sunday’s
WOD.  If you need to get judged Friday or Monday please email Daniel
with available times.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      